@import '../../styles/common';
.root {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  :global {
    .webcam {
      position: absolute;
      width: calc(100vw - 1rem);
      height: calc(100vw - 1rem); /* Ensures a square */
      top: 45%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      background: transparent;
      object-fit: cover;
      border-radius: 1.5rem;
      box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.3);
    }

    .back-button {
      position: fixed;
      left: 1rem;
      top: 2.5rem;
      background: transparent;
      width: 30px;

      .icon {
        height: 21px;
        fill: $color-white;
      }
    }

    .frame {
      align-self: center;
      position: absolute;
      width: calc(100vw - 3rem);
      height: calc(100vw - 3rem);
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }

    .preview {
      border: 1px solid black;
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: 10rem;
      z-index: 1000;
    }

    .camera {
      align-self: center;
      position: absolute;
      top: 50%;
      width: 45%;
      max-width: 150px;
      transform: translateY(-50%);
      pointer-events: none;
      z-index: -1;
    }

    .banner {
      background: rgba(37, 43, 57, 0.55);
      border-radius: 1rem;
      width: calc(100% - 3rem);
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem 0 1rem 2rem;
      box-sizing: border-box;
      position: relative;
      margin-top: 1rem;

      .label {
        flex: 1;
        font-size: 1.25rem;
        font-weight: 700;
        color: #fff;
      }

      .card {
        width: 125px;
      }

      .card-wrapper {
        flex: 1;
        text-align: center;
      }

      .сlose-icon {
        width: 1rem;
        height: 1rem;
        top: 0;
        right: 0;
        padding: 0.75rem;
      }
    }

    .actions {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;

      button {
        width: calc(100% - 2rem);
        height: 60px;
        border-radius: 8px;
        background: $color-white;
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 1rem;
        color: $color-black;
      }
    }
  }
}
